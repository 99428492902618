import React, { FC } from 'react'
import { HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { Menu } from '@mantine/core'
import { IconDotsVertical } from '@tabler/icons-react'
import IconEdit1 from '@/Icons/IconEdit1'
import IconBin from '@/Icons/IconBin'
import { openModal } from '@mantine/modals'
import { router } from '@inertiajs/react'
import useAuth from '@/Hooks/useAuth'
import XInitialAvatar from '@/Mantine/XInitialAvatar'
import StatusConfirmModal from '@/Components/StatusConfirmModal'
import EditAccountingCommentDrawer from '@/Layouts/Modals/EditAccountingCommentDrawer'
import AccountingComment = App.Models.AccountingComment

const AccountingCommentCard: FC<{ comment: AccountingComment }> = ({ comment }) => {
  const auth = useAuth()

  return (
    <HStack
      spacing="4px"
      width="50%"
      style={{
        alignSelf: auth.user.id === comment.created_by_id ? 'flex-end' : 'flex-start',
        marginLeft: auth.user.id === comment.created_by_id ? 48 : 0,
        marginRight: auth.user.id === comment.created_by_id ? 0 : 48,
        minWidth: '50%',
      }}
      alignItems="flex-start"
    >
      <HStack width="initial" style={{ marginTop: 4 }}>
        <XInitialAvatar
          name={comment.created_by ? comment.created_by.name : ''}
          bg={auth.user.id === comment.created_by_id ? XelaColor.Blue12 : XelaColor.Orange12}
          c={auth.user.id === comment.created_by_id ? XelaColor.Blue3 : XelaColor.Orange3}
        />
      </HStack>
      <VStack spacing={'4px'} bg={XelaColor.Gray12} borderRadius="16px" style={{ padding: 12 }}>
        <HStack>
          <Typography
            variant="body-small-bold"
            color={auth.user.id === comment.created_by_id ? XelaColor.Blue3 : XelaColor.Orange3}
          >
            {comment.created_by ? comment.created_by.name : ''}
          </Typography>
        </HStack>
        <HStack justifyContent="flex-end" alignItems="flex-start">
          <HStack>
            <Typography variant="body-small" color={XelaColor.Gray3}>
              <pre>{comment.comment}</pre>
            </Typography>
          </HStack>
          <HStack width="initial">
            <Menu
              shadow="md"
              position={'bottom-end'}
              width={150}
              radius={8}
              styles={{
                item: {
                  '&[data-hovered]': {
                    backgroundColor: XelaColor.Gray12,
                  },
                },
              }}
            >
              <Menu.Target>
                <div style={{ padding: '2px 4px', cursor: 'pointer' }}>
                  <IconDotsVertical size={14} color={XelaColor.Gray3} />
                </div>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  icon={<IconEdit1 />}
                  style={{
                    color: XelaColor.Gray3,
                    fontSize: '13px',
                    lineHeight: '18px',
                    fontWeight: 600,
                    padding: '8px 12px',
                  }}
                  onClick={() => {
                    openModal({
                      centered: true,
                      overlayProps: {
                        color: '#000000',
                        opacity: 0.55,
                        blur: 3,
                      },
                      closeOnEscape: false,
                      closeOnClickOutside: false,
                      withCloseButton: false,
                      radius: 16,
                      size: 600,
                      children: <EditAccountingCommentDrawer comment={comment} />,
                    })
                  }}
                >
                  Edit
                </Menu.Item>
                <Menu.Item
                  icon={<IconBin />}
                  style={{
                    color: XelaColor.Gray3,
                    fontSize: '13px',
                    lineHeight: '18px',
                    fontWeight: 600,
                    padding: '8px 12px',
                  }}
                  onClick={() => {
                    openModal({
                      centered: true,
                      overlayProps: {
                        color: '#000000',
                        opacity: 0.55,
                        blur: 3,
                      },
                      closeOnEscape: false,
                      closeOnClickOutside: false,
                      radius: 16,
                      size: 600,
                      withCloseButton: false,
                      children: (
                        <StatusConfirmModal
                          title={'Delete Comment'}
                          message={'Are you sure you want to delete this comment?'}
                          onConfirm={() => {
                            router.post(
                              route('accounting.comments.delete', {
                                id: comment.id,
                              }),
                              {},
                              {
                                preserveScroll: true,
                                preserveState: false,
                              }
                            )
                            // router.get(route('b2b.commission.invoices.comments.show'))
                          }}
                        ></StatusConfirmModal>
                      ),
                    })
                  }}
                >
                  Delete
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </HStack>
        </HStack>
        <HStack justifyContent="flex-end">
          <HStack>
            <Typography variant="caption" color={XelaColor.Gray7}>
              {comment.created_at_for_humans}
            </Typography>
          </HStack>
          <HStack width="initial">
            <div style={{ padding: '2px 5px' }}></div>
          </HStack>
        </HStack>
      </VStack>
    </HStack>
  )
}

export default AccountingCommentCard
