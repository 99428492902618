import React, { FC, useCallback, useEffect, useState } from 'react'
import { Divider, HStack, TabItem, Tabs, Typography, VStack, XelaColor } from '@/XelaReact'
import { IconBell } from '@tabler/icons-react'
import { Button, Indicator, LoadingOverlay, Popover, UnstyledButton } from '@mantine/core'
import NotificationCard from '@/Components/Notifications/NotificationCard'
import { router } from '@inertiajs/react'
import useAuth from '@/Hooks/useAuth'
import IconBellFill from '@/Icons/IconBellFill'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { updateIsLoginAsNewUser } from '@/Store/globalSlice'
import { notifications as Notifier } from '@mantine/notifications'
import useWidthResize from '@/Hooks/useWidthResize'
import LeadCreatedNotification from '@/Components/Notifications/Components/LeadCreatedNotification'
import CounsellorAssignedNotification from '@/Components/Notifications/Components/CounsellorAssignedNotification'
import LeadConvertedNotification from '@/Components/Notifications/Components/LeadConvertedNotification'
import CounsellingConvertedNotification from '@/Components/Notifications/Components/CounsellingConvertedNotification'
import TaskCreatedNotification from '@/Components/Notifications/Components/TaskCreatedNotification'
import TaskCreatedWithLeadNotification from '@/Components/Notifications/Components/TaskCreatedWithLeadNotification'
import CommentCreatedWithLeadNotification from '@/Components/Notifications/Components/CommentCreatedWithLeadNotification'
import CommentCreatedNotification from '@/Components/Notifications/Components/CommentCreatedNotification'
import CommentMentionNotification from '@/Components/Notifications/Components/CommentMentionNotification'
import CommentMentionWithLeadNotification from '@/Components/Notifications/Components/CommentMentionWithLeadNotification'
import AdmissionApplicationAppliedNotification from '@/Components/Notifications/Components/AdmissionApplicationAppliedNotification'
import AdmissionApplicationInterviewPendingNotification from '@/Components/Notifications/Components/AdmissionApplicationInterviewPendingNotification'
import AdmissionApplicationDecisionAwaitedNotification from '@/Components/Notifications/Components/AdmissionApplicationDecisionAwaitedNotification'
import AdmissionApplicationAdmittedNotification from '@/Components/Notifications/Components/AdmissionApplicationAdmittedNotification'
import AdmissionApplicationFeePaidNotification from '@/Components/Notifications/Components/AdmissionApplicationFeePaidNotification'
import VisaScheduledNotification from '@/Components/Notifications/Components/VisaScheduledNotification'
import VisaLodgedNotification from '@/Components/Notifications/Components/VisaLodgedNotification'
import VisaGrantedNotification from '@/Components/Notifications/Components/VisaGrantedNotification'
import VisaDeniedNotification from '@/Components/Notifications/Components/VisaDeniedNotification'
import AdmissionManagerAssignedNotification from '@/Components/Notifications/Components/AdmissionManagerAssignedNotification'
import VisaManagerAssignedNotification from '@/Components/Notifications/Components/VisaManagerAssignedNotification'
import LeadAcceptedNotification from '@/Components/Notifications/Components/LeadAcceptedNotification'
import AgentCommentOnLeadNotification from './Components/AgentCommentOnLeadNotification'
import AgencyCommentOnLeadNotification from '@/Components/Notifications/Components/AgencyCommentOnLeadNotification'
import AgentVisaTransitionNotification from '@/Components/Notifications/Components/AgentVisaTransitionNotification'
import VisitorLeadCreatedNotification from '@/Components/Notifications/Components/VisitorLeadCreatedNotification'
import IeltsLeadCreatedNotification from '@/Components/Notifications/Components/IeltsLeadCreatedNotification'
import LeadCounsellorAssignedNotification from '@/Components/Notifications/Components/LeadCounsellorAssignedNotification'
import PaymentReminderToLeadManagerNotification from '@/Components/Notifications/Components/PaymentReminderToLeadManagerNotification'
import LeadTransitionNotification from '@/Components/Notifications/Components/LeadTransitionNotification'
import LeadBranchChangedNotification from '@/Components/Notifications/Components/LeadBranchChangedNotification'
import LeadCounsellorChangedNotification from '@/Components/Notifications/Components/LeadCounsellorChangedNotification'
import LeadCourseAssignedNotification from '@/Components/Notifications/Components/LeadCourseAssignedNotification'
import LeadCourseUnassignedNotification from '@/Components/Notifications/Components/LeadCourseUnassignedNotification'
import CounsellingCreatedNotification from '@/Components/Notifications/Components/CounsellingCreatedNotification'
import CounsellingTransitionNotification from '@/Components/Notifications/Components/CounsellingTransitionNotification'
import CounsellingCounsellorChangedNotification from '@/Components/Notifications/Components/CounsellingCounsellorChangedNotification'
import AdmissionCreatedNotification from '@/Components/Notifications/Components/AdmissionCreatedNotification'
import AdmissionManagerChangedNotification from './Components/AdmissionManagerChangedNotification'
import AdmissionApplicationCreatedNotification from '@/Components/Notifications/Components/AdmissionApplicationCreatedNotification'
import VisaCreatedNotification from '@/Components/Notifications/Components/VisaCreatedNotification'
import VisaManagerChangedNotification from '@/Components/Notifications/Components/VisaManagerChangedNotification'
import IeltsLeadBranchChangedNotification from '@/Components/Notifications/Components/IeltsLeadBranchChangedNotification'
import IeltsLeadManagerAssignedNotification from '@/Components/Notifications/Components/IeltsLeadManagerAssignedNotification'
import IeltsLeadTransitionNotification from '@/Components/Notifications/Components/IeltsLeadTransitionNotification'
import VisitorLeadBranchChangedNotification from '@/Components/Notifications/Components/VisitorLeadBranchChangedNotification'
import VisitorLeadManagerAssignedNotification from '@/Components/Notifications/Components/VisitorLeadManagerAssignedNotification'
import VisitorLeadManagerChangedNotification from '@/Components/Notifications/Components/VisitorLeadManagerChangedNotification'
import VisitorLeadTransitionNotification from '@/Components/Notifications/Components/VisitorLeadTransitionNotification'
import VisitorLeadVisaManagerAssignedNotification from '@/Components/Notifications/Components/VisitorLeadVisaManagerAssignedNotification'
import VisitorLeadVisaManagerChangedNotification from '@/Components/Notifications/Components/VisitorLeadVisaManagerChangedNotification'
import DocumentUploadedNotification from '@/Components/Notifications/Components/DocumentUploadedNotification'
import VisaTransitionNotification from '@/Components/Notifications/Components/VisaTransitionNotification'
import AdmissionApplicationTransitionNotification from '@/Components/Notifications/Components/AdmissionApplicationTransitionNotification'
import WhatsappMessageReceivedNotification from '@/Components/Notifications/Components/WhatsappMessageReceivedNotification'
import EmailMessageReceivedNotification from '@/Components/Notifications/Components/EmailMessageReceivedNotification'
import InvoiceCreatedNotification from '@/Components/Notifications/Components/InvoiceCreatedNotification'
import InvoiceStatusTransitionNotification from '@/Components/Notifications/Components/InvoiceStatusTransitionNotification'
import InvoiceAcceptedOrRejectedNotification from '@/Components/Notifications/Components/InvoiceAcceptedOrRejectedNotification'
import B2BAnnouncementPublishedNotification from '@/Components/Notifications/Components/B2BAnnouncementPublishedNotification'
import AgentInviteAcceptedNotification from '@/Components/Notifications/Components/AgentInviteAcceptedNotification'
import TaskAssignedNotification from '@/Components/Notifications/Components/TaskAssignedNotification'
import TaskAssignedWithLeadNotification from '@/Components/Notifications/Components/TaskAssignedWithLeadNotification'
import AccountingPaymentCommentAddedNotification from '@/Components/Notifications/Components/AccountingPaymentCommentAddedNotification'
import PaymentApprovedNotification from '@/Components/Notifications/Components/PaymentApprovedNotification'
import ShareInfopediaNotification from '@/Components/Notifications/Components/ShareInfopediaNotification'
import AgentCommentOnVisitorLeadNotification from '@/Components/Notifications/Components/AgentCommentOnVisitorLeadNotification'
import AgencyCommentOnVisitorLeadNotification from '@/Components/Notifications/Components/AgencyCommentOnVisitorLeadNotification'
import StudentCommentOnLeadNotification from '@/Components/Notifications/Components/StudentCommentOnLeadNotification'
import UserCommentOnLeadNotification from '@/Components/Notifications/Components/UserCommentOnLeadNotification'
import AnnouncementPublishedNotification from '@/Components/Notifications/Components/AnnouncementPublishedNotification'
import B2CCommissionFollowUpReminderToUserNotification from '@/Components/Notifications/Components/B2CCommissionFollowUpReminderToUserNotification'
import Notification = App.Models.Notification

const audioElement = new Audio('/audios/notification.mp3')

const Notifications: FC<{ portal?: string }> = ({ portal = 'Agency' }) => {
  const auth = useAuth()
  const dimension = useWidthResize()
  const dispatch = useDispatch()
  const globalStore = useSelector((state: RootState) => state.global)
  const [tab, setTab] = useState<string>('Unread')
  const [loading, setLoading] = useState<boolean>(false)
  const [unreadNotifications, setUnreadNotifications] = useState([] as Notification[])
  const [readNotifications, setReadNotifications] = useState([] as Notification[])

  // Add the state variable for document click tracking
  const [documentClicked, setDocumentClicked] = useState<boolean>(false)
  useEffect(() => {
    const handleDocumentClick = () => {
      setDocumentClicked(true)
    }

    document.addEventListener('click', handleDocumentClick)

    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  const playAudio = useCallback(() => {
    if (!documentClicked) {
      return
    }

    audioElement.volume = 0.5
    audioElement.play()
    setTimeout(() => {
      audioElement.pause()
      audioElement.currentTime = 0
      close()
    }, 2000)
  }, [documentClicked])

  const fetchUnreadNotifications = useCallback(() => {
    setLoading(true)
    axios
      .post<{
        success: boolean
        notifications: Notification[]
      }>('/notifications/unread/fetch', {
        portal: portal,
      })
      .then((response) => {
        if (response.data.success) {
          setUnreadNotifications(response.data.notifications)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const fetchReadNotifications = useCallback(() => {
    setLoading(true)
    axios
      .post<{
        success: boolean
        notifications: Notification[]
      }>('/notifications/read/fetch', {
        portal: portal,
      })
      .then((response) => {
        if (response.data.success) {
          setReadNotifications(response.data.notifications)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    fetchUnreadNotifications()
    fetchReadNotifications()
  }, [])

  useEffect(() => {
    if (globalStore.isLoginAsNewUser) {
      fetchUnreadNotifications()
      fetchReadNotifications()

      dispatch(updateIsLoginAsNewUser(false))
    }
  }, [globalStore.isLoginAsNewUser])

  const notificationProcessor = useCallback((notification: Notification) => {
    playAudio()

    setUnreadNotifications((prevState) => [notification, ...prevState])
    let notificationJSX = <></>

    switch (notification.type) {
      case 'App\\Notifications\\B2B\\B2BAnnouncementPublishedNotification':
        notificationJSX = <B2BAnnouncementPublishedNotification notification={notification} />
        break
      case 'App\\Notifications\\Announcement\\AnnouncementPublishedNotification':
        notificationJSX = <AnnouncementPublishedNotification notification={notification} />
        break
      case 'App\\Notifications\\B2B\\AgentInviteAcceptedNotification':
        notificationJSX = <AgentInviteAcceptedNotification notification={notification} />
        break
      case 'App\\Notifications\\Hub\\LeadAcceptedNotification':
        notificationJSX = <LeadAcceptedNotification notification={notification} />
        break
      case 'App\\Notifications\\B2B\\AgentCommentOnLeadNotification':
        notificationJSX = <AgentCommentOnLeadNotification notification={notification} />
        break
      case 'App\\Notifications\\Hub\\AgencyCommentOnLeadNotification':
        notificationJSX = <AgencyCommentOnLeadNotification notification={notification} />
        break
      case 'App\\Notifications\\Lead\\LeadCreatedNotification':
        notificationJSX = <LeadCreatedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\VisitorLead\\VisitorLeadCreatedNotification':
        notificationJSX = <VisitorLeadCreatedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\IeltsLead\\IeltsLeadCreatedNotification':
        notificationJSX = <IeltsLeadCreatedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Lead\\LeadCounsellorAssignedNotification':
        notificationJSX = <LeadCounsellorAssignedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Counselling\\CounsellorAssignedNotification':
        notificationJSX = <CounsellorAssignedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Lead\\LeadConvertedNotification':
        notificationJSX = <LeadConvertedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Counselling\\CounsellingConvertedNotification':
        notificationJSX = <CounsellingConvertedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Task\\TaskCreatedNotification':
        notificationJSX = <TaskCreatedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Task\\TaskCreatedWithLeadNotification':
        notificationJSX = <TaskCreatedWithLeadNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Task\\TaskAssignedNotification':
        notificationJSX = <TaskAssignedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Task\\TaskAssignedWithLeadNotification':
        notificationJSX = <TaskAssignedWithLeadNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Payment\\PaymentReminderToLeadManagerNotification':
        notificationJSX = <PaymentReminderToLeadManagerNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Payment\\PaymentApprovedNotification':
        notificationJSX = <PaymentApprovedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Payment\\AccountingPaymentCommentAddedNotification':
        notificationJSX = <AccountingPaymentCommentAddedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\B2CCommission\\B2CCommissionFollowUpReminderToUserNotification':
        notificationJSX = (
          <B2CCommissionFollowUpReminderToUserNotification notification={notification} portal={portal} />
        )
        break
      case 'App\\Notifications\\Task\\Comment\\CommentCreatedNotification':
        notificationJSX = <CommentCreatedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Task\\Comment\\CommentCreatedWithLeadNotification':
        notificationJSX = <CommentCreatedWithLeadNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Task\\Comment\\CommentMentionNotification':
        notificationJSX = <CommentMentionNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Task\\Comment\\CommentMentionWithLeadNotification':
        notificationJSX = <CommentMentionWithLeadNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Admission\\AdmissionManagerAssignedNotification':
        notificationJSX = <AdmissionManagerAssignedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Visa\\VisaManagerAssignedNotification':
        notificationJSX = <VisaManagerAssignedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Admission\\AdmissionApplicationAppliedNotification':
        notificationJSX = <AdmissionApplicationAppliedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Admission\\AdmissionApplicationInterviewPendingNotification':
        notificationJSX = (
          <AdmissionApplicationInterviewPendingNotification notification={notification} portal={portal} />
        )
        break
      case 'App\\Notifications\\Admission\\AdmissionApplicationDecisionAwaitedNotification':
        notificationJSX = (
          <AdmissionApplicationDecisionAwaitedNotification notification={notification} portal={portal} />
        )
        break
      case 'App\\Notifications\\Admission\\AdmissionApplicationAdmittedNotification':
        notificationJSX = <AdmissionApplicationAdmittedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Admission\\AdmissionApplicationFeePaidNotification':
        notificationJSX = <AdmissionApplicationFeePaidNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Visa\\VisaScheduledNotification':
        notificationJSX = <VisaScheduledNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Visa\\VisaLodgedNotification':
        notificationJSX = <VisaLodgedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Visa\\VisaGrantedNotification':
        notificationJSX = <VisaGrantedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Visa\\VisaDeniedNotification':
        notificationJSX = <VisaDeniedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Hub\\AgentVisaTransitionNotification':
        notificationJSX = <AgentVisaTransitionNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Lead\\LeadTransitionNotification':
        notificationJSX = <LeadTransitionNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Lead\\LeadBranchChangedNotification':
        notificationJSX = <LeadBranchChangedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Lead\\LeadCounsellorChangedNotification':
        notificationJSX = <LeadCounsellorChangedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Lead\\LeadCourseAssignedNotification':
        notificationJSX = <LeadCourseAssignedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Lead\\LeadCourseUnAssignedNotification':
        notificationJSX = <LeadCourseUnassignedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Counselling\\CounsellingCreatedNotification':
        notificationJSX = <CounsellingCreatedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Counselling\\CounsellingTransitionNotification':
        notificationJSX = <CounsellingTransitionNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Counselling\\CounsellorCHangedNotification':
        notificationJSX = <CounsellingCounsellorChangedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Admission\\AdmissionCreatedNotification':
        notificationJSX = <AdmissionCreatedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Admission\\AdmissionManagerChangedNotification':
        notificationJSX = <AdmissionManagerChangedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Admission\\AdmissionApplicationCreatedNotification':
        notificationJSX = <AdmissionApplicationCreatedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Visa\\VisaCreatedNotification':
        notificationJSX = <VisaCreatedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Visa\\VisaManagerChangedNotification':
        notificationJSX = <VisaManagerChangedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\IeltsLead\\IeltsLeadBranchChangedNotification':
        notificationJSX = <IeltsLeadBranchChangedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\IeltsLead\\IeltsLeadManagerAssignedNotification':
        notificationJSX = <IeltsLeadManagerAssignedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\IeltsLead\\IeltsLeadManagerChangedNotification':
        notificationJSX = <IeltsLeadBranchChangedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\IeltsLead\\IeltsLeadTransitionNotification':
        notificationJSX = <IeltsLeadTransitionNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\VisitorLead\\VisitorLeadBranchChangedNotification':
        notificationJSX = <VisitorLeadBranchChangedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\VisitorLead\\VisitorLeadManagerAssignedNotification':
        notificationJSX = <VisitorLeadManagerAssignedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\VisitorLead\\VisitorLeadManagerChangedNotification':
        notificationJSX = <VisitorLeadManagerChangedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\VisitorLead\\VisitorLeadTransitionNotification':
        notificationJSX = <VisitorLeadTransitionNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\VisitorLead\\VisitorLeadVisaManagerAssignedNotification':
        notificationJSX = <VisitorLeadVisaManagerAssignedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\VisitorLead\\VisitorLeadVisaManagerChangedNotification':
        notificationJSX = <VisitorLeadVisaManagerChangedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Document\\DocumentUploadNotification':
        notificationJSX = <DocumentUploadedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Visa\\VisaTransitionNotification':
        notificationJSX = <VisaTransitionNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Admission\\AdmissionApplicationTransitionNotification':
        notificationJSX = <AdmissionApplicationTransitionNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Whatsapp\\WhatsappMessageReceivedNotification':
        notificationJSX = <WhatsappMessageReceivedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Whatsapp\\EmailMessageReceivedNotification':
        notificationJSX = <EmailMessageReceivedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\B2B\\InvoiceCreatedNotification':
        notificationJSX = <InvoiceCreatedNotification notification={notification} />
        break
      case 'App\\Notifications\\B2B\\InvoiceStatusTransitionNotification':
        notificationJSX = <InvoiceStatusTransitionNotification notification={notification} />
        break
      case 'App\\Notifications\\Hub\\InvoiceAcceptedOrRejectedNotification':
        notificationJSX = <InvoiceAcceptedOrRejectedNotification notification={notification} />
        break
      case 'App\\Notifications\\B2B\\ShareInfopediaNotification':
        notificationJSX = <ShareInfopediaNotification notification={notification} />
        break
      case 'App\\Notifications\\B2B\\AgentCommentOnVisitorLeadNotification':
        notificationJSX = <AgentCommentOnVisitorLeadNotification notification={notification} />
        break
      case 'App\\Notifications\\Hub\\AgencyCommentOnVisitorLeadNotification':
        notificationJSX = <AgencyCommentOnVisitorLeadNotification notification={notification} />
        break
      case 'App\\Notifications\\Lead\\StudentCommentOnLeadNotification':
        notificationJSX = <StudentCommentOnLeadNotification notification={notification} />
        break
      case 'App\\Notifications\\Lead\\UserCommentOnLeadNotification':
        notificationJSX = <UserCommentOnLeadNotification notification={notification} />
        break
    }

    Notifier.show({
      id: notification.id,
      withCloseButton: true,
      autoClose: 5000,
      title: (
        <HStack
          spacing="3px"
          style={{
            padding: '0 12px',
          }}
        >
          <Typography variant="body-bold">Notification</Typography>
        </HStack>
      ),
      message: notificationJSX,
      color: 'blueX',
      icon: <IconBell size={18} stroke={1.5} />,
      loading: false,
    })
  }, [])

  useEffect(() => {
    if (portal === 'Hub') {
      window.Echo.private('App.Models.Agent.' + auth.user.id).notification((notification: Notification) => {
        notificationProcessor(notification)
      })
    } else {
      window.Echo.private('App.Models.User.' + auth.user.id).notification((notification: Notification) => {
        if (notification.data && notification.data.agent_id) {
          if (portal === 'Agency') {
            return
          }
        }
        console.log('sfjjk')
        notificationProcessor(notification)
      })
    }
  }, [])

  return (
    <Popover
      width={dimension < 600 ? 340 : 440}
      position={dimension < 600 ? 'bottom-end' : 'left-start'}
      withArrow
      shadow="lg"
      radius={16}
      arrowOffset={20}
    >
      <Popover.Target>
        <UnstyledButton>
          <Indicator
            size={18}
            label={
              <Typography variant="sub-caption" style={{ fontSize: '9px' }}>
                {unreadNotifications.length > 9 ? '9+' : unreadNotifications.length}
              </Typography>
            }
            disabled={unreadNotifications.length === 0}
            color={XelaColor.Orange3}
            offset={3}
          >
            <HStack
              bg={XelaColor.Blue12}
              borderRadius="15px"
              style={{ padding: dimension < 600 ? '10px' : '12px' }}
              width="initial"
            >
              <IconBellFill className={'blue-3 p-[4px] md:w-7 w-6 h-6 md:h-7'} />
            </HStack>
          </Indicator>
        </UnstyledButton>
      </Popover.Target>
      <Popover.Dropdown style={{ padding: 16 }}>
        <LoadingOverlay visible={loading} loaderProps={{ color: XelaColor.Blue3 }} />
        <VStack spacing="12px" style={{ minHeight: dimension > 1280 ? '600px' : '420px' }}>
          <HStack justifyContent="space-between">
            <Typography variant="subheadline" color={XelaColor.Gray3}>
              Notifications
            </Typography>
            <Button
              size={'xs'}
              variant="white"
              color={'blueX'}
              radius="12px"
              onClick={() => {
                router.post('/notifications/mark-all-read')
                fetchReadNotifications()
                fetchUnreadNotifications()
              }}
            >
              Mark all as read
            </Button>
          </HStack>
          <HStack>
            <Divider variant="dotted"></Divider>
          </HStack>
          <Tabs
            style={{
              width: '100%',
              borderBottomWidth: '2px',
              borderBottomStyle: 'solid',
              borderBottomColor: XelaColor.Gray12,
            }}
            autoresize={false}
            items={[new TabItem('Unread', 'Unread'), new TabItem('Read', 'Read')]}
            name="notification_tabs"
            defaultValue={tab}
            onChangeHandle={(value) => {
              setTab(value)
              if (value === 'Read') {
                fetchReadNotifications()
              } else {
                fetchUnreadNotifications()
              }
            }}
          ></Tabs>
          {tab === 'Unread' && (
            <VStack
              spacing="8px"
              style={{
                flex: '1 1 auto',
                height: 0,
                overflowY: 'auto',
              }}
            >
              {unreadNotifications.length > 0 &&
                unreadNotifications
                  .filter(
                    (notification) =>
                      !['App\\Notifications\\Insurance\\InsuranceOrderPaidNotification'].includes(notification.type)
                  )
                  .map((notification, index) => {
                    return (
                      <VStack
                        spacing="12px"
                        key={notification.id}
                        onClick={() => {
                          setTimeout(() => {
                            axios.post(`/notifications/${notification.id}/mark-read`).then(() => {
                              setUnreadNotifications((prevNotifications) =>
                                prevNotifications.filter((prevNotification) => prevNotification.id !== notification.id)
                              )
                            })
                          }, 500)
                        }}
                      >
                        <NotificationCard portal={portal} notification={notification} />
                        {index !== unreadNotifications.length - 1 && (
                          <HStack>
                            <Divider></Divider>
                          </HStack>
                        )}
                      </VStack>
                    )
                  })}
              {unreadNotifications.length === 0 && (
                <VStack height="100%" alignItems="center" justifyContent="center">
                  <Typography variant="body" color={XelaColor.Gray6}>
                    No Notifications yet
                  </Typography>
                </VStack>
              )}
            </VStack>
          )}
          {tab === 'Read' && (
            <VStack
              spacing="8px"
              style={{
                flex: '1 1 auto',
                height: 0,
                overflowY: 'auto',
              }}
            >
              {readNotifications.length > 0 &&
                readNotifications
                  .filter(
                    (notification) =>
                      !['App\\Notifications\\Insurance\\InsuranceOrderPaidNotification'].includes(notification.type)
                  )
                  .map((notification, index) => {
                    return (
                      <VStack spacing="12px" key={notification.id}>
                        <NotificationCard portal={portal} notification={notification} disableRead={true} />
                        {index !== readNotifications.length - 1 && (
                          <HStack>
                            <Divider></Divider>
                          </HStack>
                        )}
                      </VStack>
                    )
                  })}
              {readNotifications.length === 0 && (
                <VStack height="100%" alignItems="center" justifyContent="center">
                  <Typography variant="body" color={XelaColor.Gray6}>
                    No Notifications yet
                  </Typography>
                </VStack>
              )}
            </VStack>
          )}
        </VStack>
      </Popover.Dropdown>
    </Popover>
  )
}

export default Notifications
