import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { RowData } from '@/Types/UniversityPaymentTypes'
import UniversityPayment = App.Models.UniversityPayment

export interface UniversityPaymentState {
  show_university_commission_drawer: boolean
  active_stepper: number
  selected_university_payment: UniversityPayment | null
  show_university_commission_update_modal: boolean
  is_payment_stage: boolean
  is_payment_status: boolean
  show_university_payment_requirement_drawer: boolean
  university_payment_requirements: {
    optionals: {
      bank_details: boolean
      tax_details: boolean
    }
    bank_details: boolean
    tax_details: boolean
  }
  show_application_drawer: boolean
  show_b2c_commission_drawer: boolean
  selected_university_name: string | null
  selected_university_country: string | null
  selected_student_rows: RowData[]
  selected_invoice_currency: string
}

const initialState: UniversityPaymentState = {
  show_university_commission_drawer: false,
  active_stepper: 0,
  selected_university_payment: null,
  show_university_commission_update_modal: false,
  is_payment_stage: false,
  is_payment_status: false,
  show_university_payment_requirement_drawer: false,
  university_payment_requirements: {
    optionals: {
      bank_details: false,
      tax_details: false,
    },
    bank_details: false,
    tax_details: false,
  },
  show_application_drawer: false,
  show_b2c_commission_drawer: false,
  selected_university_country: '',
  selected_university_name: '',
  selected_student_rows: [
    {
      course_id: '',
      course_name: '',
      lead_id: '',
      lead_name: '',
      lead_email: '',
      lead_phone: '',
      lead_dob: '',
      course_intake: '',
      fee_paid_amount: 0,
      commission_type: '%',
      commission_amount: 0,
      net_payable_amount: 0,
    },
  ],
  selected_invoice_currency: 'USD',
}

export const universityPaymentSlice = createSlice({
  name: 'universityPaymentSlice',
  initialState,
  reducers: {
    updateShowUniversityCommissionDrawer(state, action: PayloadAction<boolean>) {
      state.show_university_commission_drawer = action.payload
    },
    updateActiveStepper(state, action: PayloadAction<number>) {
      state.active_stepper = action.payload
    },
    updateSelectedUniversityPayment(state, action: PayloadAction<UniversityPayment | null>) {
      state.selected_university_payment = action.payload
    },
    updateShowUniversityCommissionUpdateModal(state, action: PayloadAction<boolean>) {
      state.show_university_commission_update_modal = action.payload
    },
    updatePaymentStatus(state, action: PayloadAction<boolean>) {
      state.is_payment_status = action.payload
    },
    updatePaymentStage(state, action: PayloadAction<boolean>) {
      state.is_payment_stage = action.payload
    },
    updateShowUniversityPaymentRequirementDrawer(state, action: PayloadAction<boolean>) {
      state.show_university_payment_requirement_drawer = action.payload
    },
    updateUniversityPaymentRequirements(
      state,
      action: PayloadAction<{
        optionals?: {
          bank_details?: boolean
          tax_details?: boolean
        }
        bank_details: boolean
        tax_details: boolean
      }>
    ) {
      state.university_payment_requirements = {
        optionals: {
          bank_details: action.payload.optionals?.bank_details ?? false,
          tax_details: action.payload.optionals?.tax_details ?? false,
        },
        bank_details: action.payload.bank_details,
        tax_details: action.payload.tax_details,
      }
    },
    updateShowApplicationDrawer(
      state,
      action: PayloadAction<{
        show: boolean
        selected_university_payment: UniversityPayment | null
      }>
    ) {
      state.show_application_drawer = action.payload.show
      state.selected_university_payment = action.payload.selected_university_payment
    },
    updateShowB2CCommissionDrawer(state, action: PayloadAction<boolean>) {
      state.show_b2c_commission_drawer = action.payload
    },
    updateSelectedUniversity(
      state,
      action: PayloadAction<{
        selected_university_name: string | null
        selected_university_country: string | null
      }>
    ) {
      state.selected_university_name = action.payload.selected_university_name
      state.selected_university_country = action.payload.selected_university_country
    },
    updateSelectedStudentRows(state, action: PayloadAction<RowData[]>) {
      state.selected_student_rows = action.payload
    },
    updateSelectedInvoiceCurrency(state, action: PayloadAction<string>) {
      state.selected_invoice_currency = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateShowUniversityCommissionDrawer,
  updateActiveStepper,
  updateSelectedUniversityPayment,
  updateShowUniversityCommissionUpdateModal,
  updatePaymentStatus,
  updatePaymentStage,
  updateShowUniversityPaymentRequirementDrawer,
  updateUniversityPaymentRequirements,
  updateShowApplicationDrawer,
  updateShowB2CCommissionDrawer,
  updateSelectedUniversity,
  updateSelectedStudentRows,
  updateSelectedInvoiceCurrency,
} = universityPaymentSlice.actions

export default universityPaymentSlice.reducer
