import React, { FC } from 'react'
import { HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { router } from '@inertiajs/react'
import Notification = App.Models.Notification

const B2CCommissionFollowUpReminderToUserNotification: FC<{
  notification: Notification
  portal?: string
}> = ({ notification }) => {
  return (
    <VStack
      onClick={() => {
        if (notification.data.invoice_number === null) return
        router.visit(route('university-commissions'))
      }}
    >
      <VStack
        spacing="3px"
        style={{
          flexWrap: 'wrap',
          cursor: 'pointer',
          padding: '6px 12px',
        }}
      >
        <Typography variant="body-small-bold">Upcoming University Commission</Typography>
        <HStack spacing={'8px'}>
          <Typography variant="body-small" color={XelaColor.Gray6}>
            University Name:
          </Typography>
          <Typography variant="body-small-bold" color={XelaColor.Orange3}>
            #{notification.data.university_name}
          </Typography>
          <Typography variant="body-small" color={XelaColor.Gray6}>
            Invoice Number:
          </Typography>
          <Typography variant="body-small-bold" color={XelaColor.Orange3}>
            #{notification.data.invoice_number}
          </Typography>
          <Typography variant="body-small" color={XelaColor.Gray6}>
            Due Date:
          </Typography>
          <Typography variant="body-small">{notification.data.b2b_commission_due_date}</Typography>
        </HStack>
        <HStack spacing={'8px'}>
          <Typography variant="body-small" color={XelaColor.Gray6}>
            Amount:
          </Typography>
          <Typography variant="body-small">{notification.data.b2b_commission_amount}</Typography>
        </HStack>
      </VStack>
    </VStack>
  )
}

export default B2CCommissionFollowUpReminderToUserNotification
