import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import B2BCommissionInvoice = App.Models.B2BCommissionInvoice

export interface B2BCommissionInvoiceState {
  invoice: B2BCommissionInvoice | null
  selected_invoice_id: number | null
  show_invoices_filter_drawer: boolean
  show_comment_drawer: boolean
  reload_all_invoices: boolean
  show_students_drawer: boolean
  current_invoices_filters: {
    [key: string]: string
  }
  previous_invoices_filters: {
    [key: string]: string
  }
  selected_invoice: B2BCommissionInvoice | null
  show_invoice_update_drawer: boolean
}

const initialState: B2BCommissionInvoiceState = {
  invoice: null,
  selected_invoice_id: null,
  show_invoices_filter_drawer: false,
  show_comment_drawer: false,
  reload_all_invoices: false,
  show_students_drawer: false,
  current_invoices_filters: {
    agent_id: '',
    b2b_manager_id: '',
    from_date: '',
    to_date: '',
    due_date_from: '',
    due_date_to: '',
  },
  previous_invoices_filters: {
    agent_id: '',
    b2b_manager_id: '',
    from_date: '',
    to_date: '',
    due_date_from: '',
    due_date_to: '',
  },
  selected_invoice: null,
  show_invoice_update_drawer: false,
}

export const b2bCommissionInvoiceSlice = createSlice({
  name: 'b2bCommissionInvoiceSlice',
  initialState,
  reducers: {
    updateInvoice(state, action: PayloadAction<B2BCommissionInvoice | null>) {
      state.invoice = action.payload
    },
    updateShowInvoicesFilterDrawer(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        state.show_invoices_filter_drawer = true
      } else {
        state.invoice = null
        state.show_invoices_filter_drawer = false
      }
    },
    updateShowCommentDrawer(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        state.show_comment_drawer = true
      } else {
        state.invoice = null
        state.show_comment_drawer = false
      }
    },
    updateSelectedInvoiceId(state, action: PayloadAction<number | null>) {
      state.selected_invoice_id = action.payload
    },
    updateCurrentInvoiceFilters(
      state,
      action: PayloadAction<{
        [key: string]: string
      }>
    ) {
      state.current_invoices_filters = action.payload
    },
    updatePreviousInvoiceFilters(
      state,
      action: PayloadAction<{
        [key: string]: string
      }>
    ) {
      state.previous_invoices_filters = action.payload
    },
    reloadAllInvoices(state, action: PayloadAction<boolean>) {
      state.reload_all_invoices = action.payload
    },
    updateShowStudentsDrawer(state, action: PayloadAction<boolean>) {
      state.show_students_drawer = action.payload
    },
    updateSelectedInvoice(state, action: PayloadAction<B2BCommissionInvoice | null>) {
      state.selected_invoice = action.payload
    },
    updateShowInvoiceUpdateDrawer(
      state,
      action: PayloadAction<{
        show: boolean
        selected_invoice: B2BCommissionInvoice | null
      }>
    ) {
      state.show_invoice_update_drawer = action.payload.show
      state.selected_invoice = action.payload.selected_invoice
    },
    updateInvoiceStatus(
      state,
      action: PayloadAction<{
        invoice_id: number
        status: string
      }>
    ) {
      if (state.invoice && state.invoice.id === action.payload.invoice_id) {
        state.invoice.invoice_status = action.payload.status
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateSelectedInvoiceId,
  updateInvoice,
  updateShowInvoicesFilterDrawer,
  updateShowCommentDrawer,
  updateCurrentInvoiceFilters,
  updatePreviousInvoiceFilters,
  reloadAllInvoices,
  updateShowStudentsDrawer,
  updateSelectedInvoice,
  updateShowInvoiceUpdateDrawer,
  updateInvoiceStatus,
} = b2bCommissionInvoiceSlice.actions

export default b2bCommissionInvoiceSlice.reducer
